
import Vue from "vue";
import { CountriesOverview } from "@/models/countriesoverview";
import ConfirmDialog from "../../components/UI-Components/ConfirmDialog.vue";
import SurveyOverviewRow from "../../components/UI-Components/SurveyOverviewRow.vue";
import moment, { locale } from "moment";
import i18n from "@/i18n";
import { Survey } from "@/models/survey";
import { Country } from "@/models/country";
import { ErrorMessage } from "@/models/errormessages";
import { SaveRes } from "@/models/saveres";
import { Snackbar } from "@/models/snackbar";
import axios from "@/plugins/axios";

export default Vue.extend({
  name: "CountryOverviews",
  data: () => ({
    search: "",
    checkedSurveys: <any>[],
    isLoading: true,
    isAddPopDisabled: false,
    initData: [] as CountriesOverview[],
    newPopulation: {} as CountriesOverview,
    showSnackBtn: false,
    isDialog: false,
    isConfirmed: false,
    dialogType: "add",
    isSnacking: false,
    snackColor: "success",
    snackTimeout: 1500,
    snackMessage: "",
    snackMessages: [] as Array<string>,
    isSnackMessageList: false,
    editingLineIndex: null as number | null,
    fileInput: null,
    file: {} as File,
    canUpload: false,
    lastLineEditedBackup: {} as any,
    rowUpdated: {} as any,
    isUploadingFile: false,
    latestYear: null as number | null,
    isAddingSurvey: false,
    selectedSurvey: {} as Survey,
  }),
  computed: {
    yearSelected: {
      get() {
        return this.$store.getters["countriesoverview/getYearSelected"];
      },
      set(year) {
        return this.$store.dispatch(
          "countriesoverview/changeYearSelected",
          year
        );
      },
    },
    countrySelected: {
      get() {
        return this.$store.getters["datamanager/getCountrySelected"] as Country;
      },
      set(value) {
        return this.$store.dispatch("datamanager/changeCountrySelected", value);
      },
    },
    surveys() {
      return this.$store.getters["datamanager/getSurveys"];
    },
    countriesOverview: {
      get() {
        return this.$store.getters["countriesoverview/getCountriesOverview"];
      },
    },
    gnis: {
      get() {
        return this.$store.getters["countriesoverview/getGnis"];
      },
    },

    lang: {
      get() {
        return i18n ? i18n.locale : "en";
      },
    },
    headers: {
      get() {
        return [
          {
            text: i18n.t("basic.lastupdate"),
            value: "lastUpdateDate",
            width: "10%",
          },

          {
            text: i18n.t("basic.country"),
            value: "countryName_en",
            width: "19%",
          },
          {
            text: i18n.t("basic.region"),
            value: "regionName_en",
            width: "15%",
          },
          {
            text: i18n.t("basic.createdby"),
            value: "createdBy",
            width: "10%",
          },
          { text: i18n.t("basic.gni"), value: "gni", width: "5%" },
          {
            text: i18n.t("basic.population"),
            value: "population",
            width: "15%",
          },
          {
            text: i18n.t("basic.completion"),
            value: "completedSectionCount",
            width: "10%",
          },

          {
            text: i18n.t("basic.isapproved"),
            value: "surveyIsApproved",
            width: "9%",
          },
          { text: i18n.t("basic.actions"), value: "", width: "7%" },
        ];
      },
    },

    savePopAndGniRes() {
      return this.$store.getters["countriesoverview/getSavePopAndGniRes"];
    },
  },
  methods: {
    setAddSurveyBtnState() {
      if (
        this.latestYear != null &&
        this.latestYear >= new Date().getFullYear()
      )
        return true;
      else return false;
    },
    setUploadBtn() {
      this.file = this.fileInput ?? ({} as File);
      if (this.file.size > 0) this.canUpload = true;
      else this.canUpload = false;
    },
    wantsToUploadFile() {
      if (this.file.size > 0) {
        this.dialogType = "file";
        this.isDialog = true;
      }
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    async fetchSurveys() {
      await this.$store.dispatch("datamanager/fetchSurveys", {
        beforeCurrentDate: false,
      });
    },

    flexProp(type: string) {
      if (type == "add") {
        return {
          title: "confirmdialog.titledefault",
          text: "confirmdialog.textdefault",
        };
      } else if (type == "edit") {
        return {
          title: "confirmdialogunsavedchanges.title",
          text: "confirmdialogunsavedchanges.text",
        };
      } else if (type == "delete") {
        return {
          title: "confirmdialog.titledelete",
          text: "confirmdialog.textdelete",
        };
      } else if (type == "file") {
        return {
          title: "confirmdialog.titleupload",
          text: "confirmdialog.textupload",
        };
      }
    },
    //Save Method
    hasConfirmed(isConfirmed: boolean, type: string = "add") {
      if (isConfirmed) {
        if (type == "file") {
          if (isConfirmed) {
            this.uploadFile();
          }
          this.isDialog = false;
        }
      }

      this.isDialog = false;
    },
    async uploadFile(): Promise<any> {
      this.isUploadingFile = true;
      if (this.file.size > 0) {
        return await this.$store
          .dispatch("countriesoverview/uploadPopulationsAndGnisData", {
            year: this.selectedSurvey.year,
            formData: this.file,
            culture: i18n.locale,
          })
          .then(() => {
            this.isUploadingFile = false;
            this.snackColor = "success";
            this.isSnackMessageList = false;
            this.snackMessages = [];
            this.snackMessage = this.$t("basic.uploadsuccess").toString();
            this.snackTimeout = 1500;
            this.isSnacking = true;
            this.reloadPageData();
          })
          .catch((err) => {
            this.snackColor = "error";
            this.isSnackMessageList = true;
            this.snackMessages = [];
            this.snackMessages.push(this.$t("basic.uploaderror").toString());
            this.snackTimeout = -1;

            let errorMessages = Object.values(err.response.data.errors) as [
              ErrorMessage
            ];
            errorMessages.forEach((message) => {
              if (message.fieldName == undefined)
                this.snackMessages.push(message.message);
              else
                this.snackMessages.push(
                  message.fieldName + " - " + message.message
                );
            });

            this.isSnacking = true;
            this.isUploadingFile = false;
          });
      } else {
        this.isUploadingFile = false;
        console.log("No file selected!");
      }
      this.isUploadingFile = false;
    },

    async fetchCurrentSurvey() {
      await axios
        .get(`/Survey/?beforeCurrentDate=${false}`)
        .then((res) => {
          this.selectedSurvey = res.data;
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;
          this.snackColor = "error";
          this.snackMessage =
            this.$t("basic.uploaderror").toString() + " " + errorMessage;
          this.snackTimeout = -1;
          this.showSnackBtn = true;
          this.isSnacking = true;
        });
    },
    async fetchGnis() {
      await this.$store.dispatch("countriesoverview/fetchGnis");
    },
    async fetchCountriesOverview() {
      this.isLoading = true;
      this.checkedSurveys = [];
      await this.$store
        .dispatch(
          "countriesoverview/fetchCountriesOverview",
          this.selectedSurvey
        )
        .then(() => {
          this.isLoading = false;
        });
    },
    async approveSurvey(isApproved: Boolean) {
      this.isLoading = true;
      console.log(this.checkedSurveys);
      await this.$store
        .dispatch("countriesoverview/approveSurveys", {
          surveyDataIds: this.checkedSurveys.map(
            (x: { surveyId: number }) => x.surveyId
          ),
          isApproved: isApproved,
        })
        .then(() => {
          this.isLoading = false;
          this.fetchCountriesOverview();
        });
    },
    async addSurvey() {
      this.isAddingSurvey = true;
      await this.$store
        .dispatch("datamanager/addSurvey", {
          culture: i18n.locale,
        })
        .then(() => {
          this.snackColor = "success";
          this.snackMessage = this.$t("basic.savesuccess").toString();
          this.snackTimeout = 1500;
          this.showSnackBtn = false;
          this.isSnacking = true;
          this.reloadPageData();
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;

          this.snackColor = "error";
          this.snackMessage =
            this.$t("basic.saveerror").toString() + " " + errorMessage;
          this.snackTimeout = -1;
          this.showSnackBtn = true;
          this.isSnacking = true;
        });
      this.isAddingSurvey = false;
    },
    reloadPageData() {
      this.fileInput = null;
      this.canUpload = false;
      this.fetchCurrentSurvey().then(() => {
        this.latestYear = parseInt(this.selectedSurvey.year);
        this.fetchSurveys();
      });
    },
  },
  created() {
    this.fetchCurrentSurvey().then(() => {
      this.latestYear = parseInt(this.selectedSurvey.year);
      this.fetchSurveys();
      this.fetchGnis();
    });
  },
  watch: {
    selectedSurvey: {
      handler() {
        this.initData = [] as CountriesOverview[];
        this.fetchCountriesOverview();
      },
      deep: true,
    },
    countriesOverview: {
      handler(res) {
        this.isLoading = true;
        this.initData = [...res];
        this.isLoading = false;
      },
      deep: true,
    },
  },
  components: {
    "confirm-dialog": ConfirmDialog,
    "surveyoverview-row": SurveyOverviewRow,
  },
});
