
import Vue from "vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import i18n from "@/i18n";
import moment, { locale } from "moment";
import { Survey } from "@/models/survey";
import { Country } from "@/models/country";
import { SaveRes } from "@/models/saveres";
import { Snackbar } from "@/models/snackbar";
import { Gni } from "@/models/gni";

export default Vue.extend({
  name: "SurveyOverviewRow",
  data: () => ({
    isDialog: false,
    isEditable: false,
    tempPop: null as number | null,
    tempGni: {} as Gni | null,
  }),
  props: {
    select: {
      type: Function,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    prop: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    selectedSurvey: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gnis: {
      get() {
        return this.$store.getters["countriesoverview/getGnis"];
      },
    },

    lang: {
      get() {
        return i18n ? i18n.locale : "en";
      },
    },
    currentSurvey: {
      get() {
        return this.$store.getters["datamanager/getCurrentSurvey"] as Survey;
      },
      set(survey) {
        return this.$store.dispatch("datamanager/changeCurrentSurvey", survey);
      },
    },
    fetchCountryByIdResult() {
      return this.$store.getters["datamanager/getFetchCountryByIdResult"];
    },
    countrySelected: {
      get() {
        return this.$store.getters["datamanager/getCountrySelected"] as Country;
      },
      set(value) {
        return this.$store.dispatch("datamanager/changeCountrySelected", value);
      },
    },
    savePopAndGniRes() {
      return this.$store.getters["countriesoverview/getSavePopAndGniRes"];
    },
  },
  methods: {
    triggerEditLine(isEditable: boolean, pop: number = 0, gniId: number = 0) {
      this.isEditable = isEditable;
      if (pop != 0) {
        this.tempPop = pop;
      }
      if (gniId && gniId != 0) {
        let gni = this.gnis.find((x: Gni) => x.id == gniId);
        this.tempGni = JSON.parse(JSON.stringify(gni));
      }
    },

    momentinYear: function (date: string) {
      if (date) {
        return moment(date).format("YYYY/MM/DD");
      } else return "";
    },
    async saveCountryPopulationAndGniChanges(
      id: any,
      population: Number | null,
      gni: String | null
    ) {
      //let survey = this.currentSurvey as Survey;
      let survey = this.selectedSurvey as Survey;
      await this.$store.dispatch(
        "countriesoverview/saveCountryPopulationAndGniChanges",
        {
          countryId: id,
          year: survey.year,
          gniTypeId: gni,
          population: population,
        }
      );
      return this.savePopAndGniRes as SaveRes;
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    async fetchCountryById(loggedInUserid: string) {
      await this.$store.dispatch("datamanager/fetchCountryById", {
        id: loggedInUserid,
      });
      return this.fetchCountryByIdResult as SaveRes;
    },
    goToCountryCurSurvey(countryid: any) {
      //Set current survey in store so that the right survey is loaded in NewData
      this.currentSurvey = this.selectedSurvey;
      this.fetchCountryById(countryid).then((fetchRes) => {
        this.countrySelected = fetchRes.data;
        this.$router.push({ name: "NewData" });
      });
    },
    saveChanges(id: any, population: Number | null, gni: String | null) {
      if (population == 0) population = null;
      this.saveCountryPopulationAndGniChanges(id, population, gni)
        .then((res) => {
          if (res.status == 200) {
            if (this.tempGni) {
              this.item.gni = this.tempGni.name_en;
              this.item.gniTypeId = this.tempGni.id;
            } else {
              this.item.gni = null;
              this.item.gniTypeId = null;
            }

            this.item.population = population;
            this.isEditable = false;

            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            let snack = {
              isSnacking: true,
              snackColor: "orange",
              snackMessage:
                this.$t("basic.savewarning").toString() +
                err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          } else {
            let snack = {
              isSnacking: true,
              snackColor: "error",
              snackMessage:
                this.$t("basic.saveerror").toString() + err.response.statusText,
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
          }
        });
    },
  },
  watch: {
    prop: {
      handler() {
        this.isEditable = false;
      },
    },
  },
  components: {
    "confirm-dialog": ConfirmDialog,
  },
});
